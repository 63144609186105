<template>
  <div class="home">
    <div class="navbar">
        <div class="pl-5 ml-lg-5 py-4">
            <router-link to="/" class="back">
                <img src="../assets/ic_back.svg" class="mr-3" />
                Home
            </router-link>
        </div>
    </div>
    <hero>
      <h1 class="hero-title">We do <span>direct mail</span> for the internet</h1>
      <p class="hero-description pl-3 pr-3">All Sorts is a beautifully designed catalog featuring a curated collection of quality DTC products that we distribute to thousands of homes every season.</p>
    </hero>
    
    <div class="main" id="section2">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-8 block center">
            <h2>Why mail?</h2>
            <p>Mail is an underrated acquisition channel, which provides novelty, a trust factor, and multiple touch points.</p>
          </div>
        </div>
        <div class="row justify-content-between reverse-mobile">
          <div class="col-lg-6 col-md-6 block middle">
            <h2>Mail stands out</h2>
            <p>Direct mail was once a dominant form of advertising but has taken a backseat to online ads. Now digital devices are crowded while mailboxes are mostly empty. Boomers are nostalgic for the catalog and millennials treat mail as unique and important.</p>
          </div>
          <div class="col-lg-5 col-md-6 block image">
            <img src="../assets/mail-stands-out@2x.png" alt="Mail Stands Out" style="width: 383px;"/>
          </div>
        </div>
        <div class="row justify-content-between">
          <div class="col-lg-5 col-md-6 block image">
            <img src="../assets/mail-is-trustworthy@2x.png" alt="Mail is Trustworthy" style="width: 427px;" />
          </div>
          <div class="col-lg-5 col-md-6 block middle">
            <h2>Mail is trustworthy</h2>
            <p>Print advertising has demonstrated in surveys to be trusted by 82% of readers, compared to 25-65% online.</p>
          </div>
        </div>
        <div class="row justify-content-between reverse-mobile">
          <div class="col-lg-5 col-md-6 block middle">
            <h2>Mail converts</h2>
            <p>30% of respondents to another survey say they looked up a product online after seeing it advertised in print.</p>
          </div>
          <div class="col-lg-5 col-md-6 block image">
            <img src="../assets/mail-converts@2x.png" alt="Mail Converts" style="width: 427px;" />
          </div>
        </div>
      </div>
    </div>
    <div class="catalog">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="catalog-block">
              <img src="../assets/catalog-cover.jpg" alt="Catalog" style="width: 153px;"/>
              <div class="copy">
                <h2>Our Catalog</h2>
                <p>Download the first edition of the All Sorts catalog.</p>
              </div>
              <a href="/catalog" class="btn btn-primary" target="_blank" rel="noopener noreferrer">View Catalog</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="main">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-8 block center email">
            <h2>Sound interesting?</h2>
            <p>We’d love to chat. Please add your details below and we’ll be in touch within 48 hours.</p>
            <form class="form" v-on:submit.prevent="submit">
              <div class="form-group">
                <label for="name" class="sr-only">Your name</label>
                <input type="text" name="name" id="name" placeholder="Your name" class="form-control" v-model="name" />
                <div :class="[ 'invalid-feedback', { show: errors.indexOf('name') > -1 } ]">
                  Please enter your name
                </div>
              </div>
              <div class="form-group">
                <label for="email" class="sr-only">Email address</label>
                <input type="email" name="email" id="email" placeholder="Email address" class="form-control" v-model="email" />
                <div :class="[ 'invalid-feedback', { show: errors.indexOf('email') > -1 } ]">
                  Please enter your email
                </div>
              </div>
              <div class="form-group">
                <label for="phone-number" class="sr-only">Phone number</label>
                <input type="tel" name="phone-number" id="phone-number" placeholder="Phone number" class="form-control" v-model="phoneNumber" />
              </div>
              <div class="form-group">
                <label for="brand-name" class="sr-only">Brand name</label>
                <input type="text" name="brand-name" id="brand-name" placeholder="Brand name" class="form-control" v-model="brandName" />
              </div>
              <div class="form-group">
                <label for="brand-url" class="sr-only">Brand URL</label>
                <input type="text" name="brand-url" id="brand-url" placeholder="Brand URL" class="form-control" v-model="brandURL" />
              </div>              
              <div class="form-group">
                <label for="message" class="sr-only">Enter message</label>
                <textarea rows="5" name="message" id="message" placeholder="Enter message" class="form-control" v-model="message"></textarea>
                <div :class="[ 'invalid-feedback', { show: errors.indexOf('message') > -1 } ]">
                  Please enter your message
                </div>
              </div>
              <div class="form-group bottom">
                <button type="submit" class="btn btn-primary" :disabled="isLoading">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" v-if="isLoading"></span>
                  <span v-else>Send</span>
                </button>
                <div :class="[ 'invalid-feedback', { show: errors.length > 0 } ]">
                  Some fields above have errors
                </div>
                <div class="invalid-feedback show" v-if="formError">
                  {{ formError }}
                </div>
                <div class="valid-feedback show" v-if="isSent">
                  Thanks! Your message has been sent.
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <footer-component></footer-component>
  </div>
</template>

<script>
import axios from "axios"
import Hero from '../components/Hero.vue';
import Footer from '../components/Footer.vue';
export default {
  name: 'Home',
  data: () => {
    let now = new Date()
    return {
      year: now.getFullYear(),
      name: "",
      brandName: "",
      brandURL: "",
      message: "",
      email: "",
      phoneNumber: "",
      errors: [],
      isLoading: false,
      isSent: false,
      formError: "",
    }
  },
  methods: {
    /**
     * Encode form data for xhr request
     */
    encode (data) {
      return Object.keys(data)
        .map(
          key => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`
        )
        .join("&");
    },
    /**
     * Handle submitting the form
     */
    submit: function() {
      // reset validation
      this.errors = []
      this.isLoading = false
      this.isSent = false
      this.formError = ''

      // validate
      const fields = [
        'name',
        'email',
        'message'
      ]

      // ensure each field is present
      fields.forEach((field) => {
        if (this[field].trim() == '') {
          this.errors.push(field)
        }
      })

      // validate email
      if (!/@/.test(this.email)) {
        this.errors.push('email')
      }

      if (this.errors.length == 0) {
        // no errors, set loading state
        this.isLoading = true

        const axiosConfig = {
          header: { "Content-Type": "application/x-www-form-urlencoded" }
        };

        // send request
        axios.post(
            "/",
            this.encode({
              "form-name": "contact",
              "name": this.name,
              "email": this.email,
              "phone": this.phoneNumber,
              "brand": this.brandName,
              "brandUrl": this.brandURL,
              "message": this.message,
            }),
            axiosConfig
          )
          .then(() => {
            // success, reset
            this.name = ''
            this.email = ''
            this.phoneNumber = ''
            this.brandName = ''
            this.brandURL = ''
            this.message = ''
            this.isLoading = false

            // show success state
            this.isSent = true
          })
          .catch((err) => {
            // eslint-disable-next-line
            console.log(err)

            // show error
            this.isLoading = false
            this.formError = 'Sorry, something went wrong. Please try again later.'
          })
      }
    }
  },
  components: {
    Hero,
    'footer-component': Footer
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/_variables";
.navbar{
    .back{
        color: black;
        &:hover {
            text-decoration: none!important;
        }
    }
}
.hero-title{
  color:#000000;
  margin-top: 4rem;
  margin-bottom: 1.8125rem;
  font-size: 3.125rem;
  line-height: calc(64 / 50);
  max-width: 500px;
  @include md {
    margin-top: 3rem;
    font-size: 2rem;
    max-width: 290px;
  }

  span {
      color: $green;
  }
}
.hero-description{
  line-height: calc(26 / 16);
  margin: 0;

  @media all and (min-width: $mediumMax + 1) {
  padding-left: 30px;
  padding-right: 30px;
  }
}
div.main {
  div.row {
    &.reverse-mobile {
      @include sm {
        flex-direction: column-reverse;
      }
    }
  }

  div.block {
    padding-top: 7.5rem;

    @include sm {
      padding-top: 5rem;
    }

    &.center {
      text-align: center;

      h2 {
        position: relative;
        padding-bottom: 1.375rem;
        margin-bottom: 1.4375rem;

        &:after {
          content: "";
          width: 56px;
          height: 1px;
          background-color: black;
          position: absolute;
          bottom: 0;
          left: calc(50% - (56px / 2));
        }
      }
    }

    &.image {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.middle {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
    }

    &.email {
      p {
        @media all and (min-width: $mediumMax + 1) {
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }

    h2 {
      font-size: 2.1875rem;
      line-height: calc(45 / 35);
      margin-bottom: 1.8125rem;
      color: black;
    }

    p {
      line-height: calc(26 / 16);
      margin: 0;
    }

    img {
      max-width: 100%;
      height: auto;

      @include sm {
        max-width: 75%;
      }
    }

    form.form {
      margin-top: 2.815rem;
      margin-left: 65px;
      margin-right: 65px;
      @include sm {
        margin-left: 5px;
        margin-right: 5px;
      }

      div.form-group {
        &.bottom {
          margin-top: 2rem;
        }
      }

      input.form-control,
      textarea.form-control {
        font-size: 1rem;
        border-color: #E9E9E9;
        border-radius: 3px;
        height: calc(1.5em + 1.25rem + 2px);
        padding: 0.75rem 0.75rem;

        &::placeholder {
          color: #C5C5C5;
        }

        &:focus {
          outline: none;
          box-shadow: 0px 0px 1px 2px rgba(#5CA79E, 0.5);
        }
      }

      textarea.form-control {
        height: auto;
      }

      button.btn.btn-primary {
        font-size: 1.125rem;
        padding: 0.5rem 4rem;
      }

      div.invalid-feedback,
      div.valid-feedback {
        &.show {
          display: block;
          text-align: left;
          padding: 0.5rem;
        }
      }

      div.bottom {
        div.invalid-feedback,
        div.valid-feedback {
          text-align: center;
        }
      }
    }
  }
}

div.catalog {
  margin-top: 9.6875rem;

  @include sm {
    margin-top: 5rem;
  }

  div.container {
    border-color: #E6E1DB;
    border-width: 1px 0;
    border-style: solid;
    padding: 5.8125rem 15px 5.1875rem;

    @include sm {
      padding: 3rem 15px;
    }
  }

  div.catalog-block {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include sm {
      flex-direction: column;
    }

    img {
      margin-right: 2.8125rem;
      max-width: 100%;
      height: auto;

      @include sm {
        margin-bottom: 2rem;
        margin-right: 0;
      }
    }

    h2 {
      font-size: 2.1875rem;
      color: black;
      line-height: calc(45 / 35);
      margin: 0 0 1.4375rem;

      @include sm {
        text-align: center;
      }
    }

    div.copy {
      flex-grow: 1;

      p {
        max-width: 415px;

        @include sm {
          max-width: 75%;
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          margin-bottom: 2rem;
        }
      }
    }

    a.btn.btn-primary {
      font-size: 1.125rem;
      padding: 0.5rem 2rem;
    }
  }
}

div.footer {
  margin-top: 6rem;
  background-color: white;
  text-align: center;
  color: #AFAFAF;
  padding: 3.5rem 0;

  ul.social {
    list-style: none;
    margin: 0 0 1.4375rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    li {
      padding: 0 1rem;
    }
  }
}
</style>
