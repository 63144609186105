<template>
  <div>
    <stripe-checkout
      ref="checkoutRef"
      mode="payment"
      :session-id="sessionId"
      :pk="publishableKey"
      :success-url="successURL"
      :cancel-url="cancelURL"
      @loading="v => loading = v"
    />
    <button @click="submit">Pay now</button>
  </div>
</template>

<script>
import { StripeCheckout } from '@vue-stripe/vue-stripe';
import axios from 'axios';

export default {
  components: {
    StripeCheckout,
  },
  data () {
    this.publishableKey = "pk_live_51IeM3pFJDDJsTGtgZcoED6BYY7j8feWuwHpXJOM6GzafYo1mSnPDwxKqqPwZ4QQBg16J9ipdrAdt6KtLsAXy8jKe00sbumeKD9";
    
    return {
      loading: false,
      sessionId: null,
      lineItems: [
        {
          price: 'price_1JPWSaFJDDJsTGtguo5Rm1Kn', // live product
          quantity: 1,
        },
      ],
      successURL: window.location.protocol + '//www.notion.so/All-Sorts-On-Boarding-3072ba512aa44017a89c6bd299516823#93f12c2ca2bc4aecbaf465d0a50916f4',
      cancelURL: window.location.protocol + '//www.notion.so/All-Sorts-Partner-Onboarding-933aa7a1fa55434d9912131513ae58e0#c96120c1482a4d2e9cde08bb1447b6e4',
    };
  },
  mounted: function() {
    axios.get('/.netlify/functions/checkout')
      .then((resp) => {
        this.sessionId = resp.data.sessionId;
      });
  },
  methods: {
    submit () {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },
  },
};
</script>

<style lang="scss" scoped>

button {
  background-color: #68B5AE;
  color: #FFF;
  padding: 8px 12px;
  border: 0;
  border-radius: 4px;
  font-size: 1em;
  margin: 0 auto;
  display: block;
}

</style>