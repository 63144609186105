<template>
  <div class="header">
      <div class="background"></div>
      <div class="blobs"></div>
      <div class="doodles" v-if="currentRouteName == 'Home'"></div>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-10 content">
            <img src="../assets/allsorts.png" alt="All Sorts" class="logo" v-if="currentRouteName == 'Home'" />            
            <slot />
          </div>
        </div>
      </div>
      <div class="arrow">
        <a href="#section2">
          <img src="../assets/down-arrow.svg" />
        </a>
      </div>
    </div>
</template>

<script>
export default {
  name: 'Hero',
  props: {
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../scss/_variables";
div.header {
    position: relative;
    padding: 6rem 0 10.4375rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);

    @include md {
        padding-bottom: 5rem;
    }

    div.background {
        background-image: url('../assets/header-bg.jpg');
        background-size: cover;
        background-position: center center;
        opacity: 0.90;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    div.blobs {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../assets/blob-top-left.png'),
        url('../assets/blob-top-right.png');
        background-position: top left,
        top right;
        background-repeat: no-repeat,
        no-repeat;
        background-size: auto,
        auto;

        @include md {
        background-size: 25% auto,
            25% auto;
        background-position: top left,
            top right;
        }
    }

    div.doodles {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-image: url('../assets/doodles-left.png'),
        url('../assets/doodles-right.png');
        background-position: left 30px center,
        right center;
        background-repeat: no-repeat,
        no-repeat;
        background-size: 292px auto,
        293px auto;

        @include md {
        background-size: 25% auto,
            25% auto;
        background-position: left -25px center,
            right -35px center;
        }
    }

    div.content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        @media all and (min-width: $mediumMax + 1) {
        padding-left: 45px;
        padding-right: 45px;
        }
    }

    img.logo {
        width: 305px;
        height: auto;
        max-width: 100%;
    }

    div.arrow {
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        z-index: 2;
        cursor: pointer;

        img {
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.12);
        border-radius: 50%;
        }
    }
    }
</style>